<template>
  <div class="map-marker-detail">
    <div class="wrapper">
      <div class="title font-md">
        <b>{{ modalParams.title }}</b>
      </div>
      <div class="desc font-sm">도시재생 VR 콘텐츠, 교육 VR 콘텐츠 제작</div>
      <div class="content">
        <div class="information">
          <div class="rect">
            <div class="icon">
              <span class="img" style="background-image: url(/assets/ico/modal.map.markers.prize.svg)"></span>
            </div>
            <div class="head">
              <div class="subject">미션</div>
              <div class="side">Mission</div>
            </div>
            <div class="content">사회적경제와 시민자본의 연결</div>
          </div>
          <div class="rect">
            <div class="icon">
              <span class="img" style="background-image: url(/assets/ico/modal.map.markers.flag.svg)"></span>
            </div>
            <div class="head">
              <div class="subject">비전</div>
              <div class="side">Vision</div>
            </div>
            <div class="content">지속가능한 성장을 지원하는 ESG 크라우드펀딩 플랫폼 연결</div>
          </div>
          <div class="rect">
            <div class="icon">
              <span class="img" style="background-image: url(/assets/ico/modal.map.markers.uniform.svg)"></span>
            </div>
            <div class="head">
              <div class="subject">핵심가치</div>
              <div class="side">Values</div>
            </div>
            <div class="content">소셜 임팩트, 지속가능한 성장, 로컬 지향</div>
          </div>
        </div>
        <div class="funding">
          <div class="rect">
            <div class="icon">
              <span class="img" style="background-image: url(/assets/ico/modal.map.markers.ground.svg)"></span>
            </div>
            <div class="head">
              <div class="subject">진행 중인 프로젝트</div>
              <div class="side">Projects</div>
            </div>
            <div class="row">
              <template v-if="state.loaded">
                <div class="col-lg-3" v-for="(p, idx) in state.projects" :key="idx">
                  <Card
                      page="home"
                      :projectSeq="p.projectSeq"
                      :link="p.linkUrl"
                      :thumbFilePath="p.thumbFilePath"
                      :amount="p.investAmt"
                      :projectName="p.projectName"
                      :progressOrder="p.progressOrder"
                      :projectCate="p.projectCate"
                      :fundingType="p.fundingType"
                      :count="p.investorCount"
                      :percent="Number(p.per)"
                      :dday="Number(p.dday) === 0 ? 0 : Number(p.dday || p.dDay)"
                      :builderName="p.builderName"
                      :builderImageUrl="p.builderImageUrl"
                      :builderSeq="p.builderSeq"
                      :projectType="p.projectType"
                      :interest="true"
                      :mainImagePosition="p.mainImagePosition"
                  />
                </div>
              </template>
              <template v-else>
                <div class="col-lg-3" v-for="i in 4" :key="i">
                  <Card :skeleton="true"
                        builderName="wait a moment"
                        projectName="wait"
                        projectCate="wait"
                        page="home"
                        :count="0"
                        :amount="0"
                        :percent="0"
                  />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import Card from "@/components/Card";

function Component(initialize) {
  this.name = "modalMapMarkerDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Card},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "xl"});

      state.loaded = false;
      http.get("/api/main/projects?category=recommend&projectType=reward").then(({data}) => {
        state.loaded = true;
        state.projects = data.body;
      });
    });

    const state = reactive({
      loaded: false,
      projects: [],
    });

    const modalParams = store.getters.modalParams(component);

    return {component, modalParams, state,};
  }
});
</script>

<style lang="scss" scoped>
.map-marker-detail {
  > .wrapper {
    background: #fff;
    padding: $px30;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #666;
      white-space: pre-line;
    }

    .content {
      .rect {
        padding: $px25;
        border: 1px solid #eee;

        .icon {
          display: flex;

          .img {
            width: $px25;
            height: $px25;
          }
        }

        .head {
          display: flex;
          gap: $px2;
          align-items: baseline;
          padding-top: $px7;
          line-height: 1;

          .subject {
            font-weight: 600;
            padding: $px10 0;
          }

          .side {
            margin-left: $px5;
            color: #999;
            font-size: $px13;
          }
        }
      }

      > .information {
        display: flex;
        gap: $px20;

        .rect {
          width: calc(100% / 3);
        }
      }

      > .funding {
        margin-top: $px20;

        .rect {
          .icon {
            margin-right: $px5;

            .img {
              transform: rotate(90deg);
            }
          }

          .head {
            padding-bottom: $px10;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    > .wrapper .content {
      > .information {
        flex-direction: column;

        .rect {
          width: 100%;
        }
      }

      > .funding .rect .row > div:not(:last-child) {
        margin-bottom: $px30;
      }
    }
  }
}
</style>